<template>
  <div class="container">
    <div class="loader"></div>
    <p>Please wait while we're logging you in...</p>
  </div>
</template>

<script>
import { authMethods } from "@/state/helpers";

export default {
  middleware: ['guest'],
  data() {
    return {
      token: this.$route.params.code ? this.$route.params.code : null,
      storageRedirectedFrom: null
    }
  },
  created(){
    if (localStorage.getItem("routeRedirectFromBeforeADLogin") !== null) {
      this.storageRedirectedFrom = JSON.parse(localStorage.getItem("routeRedirectFromBeforeADLogin"));
      localStorage.removeItem("routeRedirectFromBeforeADLogin");
    }
  },
  methods: {
    ...authMethods,
    takeToken() {

      return (

        this.logInSocial({token: this.token})
          .then(() => {
            this.$router.push(
              this.storageRedirectedFrom || { name: "home" }
            );
          })
          .catch(error => {
            console.log(error);
          })
      );
    },
  },
  mounted() {
    this.takeToken();
  }
}
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
}

.loader {
  margin: 20px auto;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3B5998;
  border-bottom: 8px solid #3B5998;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1.2s linear infinite;
  animation: spin 1.2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
